* {
  box-sizing: border-box;
}

html {
  color-scheme: dark;
  background-color: #eff2f7;
}

html,
body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0px;
}

body {
  display: flex;
  flex-direction: column;
}

header {
  padding: 1rem 2rem;
}

label {
  color: #000000;
}

footer {
  width: 100%;
  padding: 1.5rem 2rem;
  text-align: center;
  color: #000000;
  background-color: var(--lk-bg);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

footer a,
h2 a {
  color: #ff6352;
  text-decoration-color: #a33529;
  text-underline-offset: 0.125em;
}

footer a:hover,
h2 a {
  text-decoration-color: #ff6352;
}

h2 a {
  text-decoration: none;
}

h3 {
  font-weight: 500 !important;
}

.join-datetime {
  color: #000000;
  font-size: 1.125rem;
  margin-top: 0;
}

.lk-prejoin-container {
  min-width: 25rem;
  box-shadow: 7px 0 20px #cfd5de;
  padding: 2rem !important;
  margin: 1rem;
  border-radius: 0.625rem;
  background-color: #eff2f7 !important;
  width: 30rem;
  /* min-height: 35.25rem; */
}

.lk-prejoin {
  background-color: #eff2f7 !important;
  padding: 0 !important;
}

.lk-button-group {
  border-radius: 0.625rem;
  box-shadow: -8px -8px 8px #fff, 5px 5px 8px #cbd9f0;
  background-color: #eff2f7 !important;
}

.lk-button-group .lk-button {
  color: #000000;
  background-color: #eff2f7 !important;
  border-radius: 0.625rem;
}

.lk-control-bar > .lk-button {
  border-radius: 0.625rem;
  color: #000000;
  background-color: #eff2f7 !important;
  box-shadow: -8px -8px 8px #fff, 5px 5px 8px #cbd9f0;
}

.lk-prejoin .lk-button-group-container .lk-button-group .lk-button {
  padding: 0.814rem 1rem !important;
}

.lk-media-device-select [data-lk-active=true]>.lk-button {
  color: #40cc52 !important;
}

.lk-button-group .lk-device-menu {
  background-color: #eff2f7 !important;
}

.lk-button-group .lk-device-menu .lk-button:hover {
  background-color: #e5e9ee !important;
}

.lk-prejoin .lk-username-container .lk-button {
  height: 3.125rem;
  font-size: 1.125rem !important;
  border-radius: 0.625rem;
  color: #ffffff;
  background-color: #40cc52 !important;
  border: none;
  cursor: pointer;
  box-shadow: -8px -8px 8px #fff, 5px 5px 8px #cbd9f0;
}

.lk-prejoin .lk-username-container input {
  width: 100%;
  color: #000000;
  height: 3.125rem;
  border-radius: 0.625rem;
  font-size: 1rem;
  padding: 0.75rem;
  background: inherit;
  border: 2px solid #d0daea;
  outline: none !important;
}

.lk-control-bar {
  background-color: #e5e9ee !important;
}

.lk-disconnect-button {
  border-radius: 0.625rem;
  background-color: #eff2f7 !important;
  box-shadow: -8px -8px 8px #fff, 5px 5px 8px #cbd9f0;
}

.lk-disconnect-button:hover, .lk-disconnect-button:active {
  color: #fa3343 !important;
}

.lk-chat-form {
  background-color: #eff2f7 !important;
}

.lk-chat-form input {
  color: #000000 !important;
  background-color: #eff2f7 !important;
  outline: none;
}

.lk-chat-form button {
  border-radius: 0.625rem;
  color: #ffffff;
  background-color: #40cc52 !important;
  box-shadow: -8px -8px 8px #fff, 5px 5px 8px #cbd9f0;
}

.lk-list {
  padding: 0.5rem !important;
}

.lk-settings-menu-modal {
  background-color: #eff2f7 !important;
  color: #000000;
  padding: 2rem !important;
}

.lk-settings-menu-modal .SettingsMenu_tabs__uq0cC {
  border-radius: 0.625rem;
}

.lk-settings-menu-modal .SettingsMenu_tabs__uq0cC .lk-button {
  font-size: 1.125rem;
  font-weight: 500;
  color: #000000;
  background-color: #eff2f7 !important;
}

.lk-settings-menu-modal .SettingsMenu_settingsCloseButton__mdSd2{
  border-radius: 0.625rem;
  color: #ffffff;
  background-color: #40cc52 !important;
  box-shadow: -8px -8px 8px #fff, 5px 5px 8px #cbd9f0;
  bottom: 2rem !important;
  right: 2rem !important;
}

.lk-settings-menu-modal button {
  cursor: pointer;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  animation: spin 2s linear infinite;
  margin: auto;
  position: relative;
  top: -3rem;
}

.viewer-container .lk-prejoin input, 
.viewer-container .lk-prejoin .lk-button-group-container {
  display: none;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (max-width: 480px) {
  .lk-prejoin-container {
    min-width: auto;
    width: calc(100% - 2rem);
  }
}
